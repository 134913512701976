#home {
    background: linear-gradient(
        to bottom right,
        #6889FF, #C668FF
    );
    background-size: 100%;
    background-attachment: fixed;

    position: relative;
    z-index: -1;
}

.home-section {
    h1 {
        font-size: clamp(50px, 5vw, 5vw);
        margin: 0;
        padding: 0;
    }
    .home-text {
        width: 40vw;
        height: 100%;
        font-size: clamp(15px, 1.2vw, 1.2vw);

        padding: 55% 0 0 40%;
        position: relative;
        z-index: 2;
    }
    width: 100%;
    height: 100%;
}

.profile-pic {
    z-index: 1;
    background: $picture-colour;
}

.profile-border {
    z-index: 1;
    background-image: url("../../frame.png");
    background-repeat: no-repeat;
}

.profile-container {
    width: 98vw;
    height: 28vh;
    position: relative;
    text-align: center;
    top: 7vh;
}

@media (min-width: 1025px) {
    .profile-pic {
        position: absolute;
        right: 17%;
        top: 50%;
        width: 30vw;
        height: 32vw;
        background-position: center;
    }

    .profile-border {
        position: absolute;
        right: 17%;
        top: 50%;
        width: 40vw;
        height: 42vw;
        background-position: center;
    }

    .home-section {
        margin-top: -45%;
        margin-left: -20%;
    }
}

@media (max-width: 1024px) {
    .profile-pic {
        position: static;
        width: 50vw;
    }

    .home-text, #home.home-section .h1.home-name {
        margin-top: -40%;
        margin-left: -25%;
    }
}