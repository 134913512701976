.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 50px;
    height: $header-height;
    background-color: $header-colour;
}

.headerLink {
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    padding-left: 20px;
}